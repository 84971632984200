<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-29 17:25:31
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-07-13 19:10:18
 * @FilePath: \vue-official-website-of-xiaomi-master\src\components\recruit\Recruit.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="page-main home-main">
      <SiteTopbar/>
      <div class="headers-imgs">
        <img src="../../assets/imgs/TKK/xie.png" width="100%">
      </div>
    <div class="containers">
        <p>
        *适用于小秤砣旗下：图哐哐、ISOabc相关产品。<br/><br/>
一、介绍<br/>
深圳市小秤砣科技有限公司（以下简称“本公司”）基于本协议的规定为您提供服务，本协议是受法律保护的协议。<br/>
对于内容中所含的版权和其他所有权声明，您应予以尊重并合法使用。在使用本网站各项服务前，请您务必仔细阅读本声明。当您在使用我们的APP服务时，我们可能会收集处理您的人个信息。公司APP的内容，仅供您个人而非商业用途的使用，如果您使用本服务，您的使用行为将被视为对本协议全部内容的认可。<br/>
您不得以任何方式修改、复制、公开展示、公布或分发这些材料或者以其他方式把它们用于任何公开或商业目的。<br/>
本公司始终有权保留修改本条款的权利，如果我们对本协议做出修改，我们将以发送邮件、短信或者在小秤砣官方媒体发布公告的方式来通知您。<br/>
为了您能及时接收通知，建议您在联系方式更新时及时通知我们。<br/>
<br/>
二、结构<br/>
（一）通则<br/>
本公司是为制造业相关领域设计师、产品工程师、制造工厂、制造从业者及相关人员提供三维CAD软件和服务的专业软件公司。<br/>
《图哐哐》的核心功能包括产品3D展示查看、数据转换、零件设计、装配设计、工程图、版本管理、协同设计等，本公司对核心功能的展示，请详见公司官媒。<br/><br/>

本协议是您与本公司之间的有关该产品和订阅服务方面的全部和唯一协议，在发生服务纠纷时，我们将以本协议为依据。<br/>
您不得修改该产品或据此制作衍生作品，或者进行包括该产品的汇编或合编，不得出于与本公司竟争的目的而分析、进行反向工程、反编译、反汇编或其他企图获取该产品源码的尝试（除适用法律所规定外），因为该产品含有本公司及其许可的商业机密（诸如该软件的结构、组织和编码）。<br/>
如果您不接受或违反上述约定，您使用本站的授权将自动终止，同时您应立即销毁任何已下载或打印好的本站内容。<br/>
（二）数据控制人<br/>
我们APP的数据控制人为深圳市小秤砣科技有限公司，本公司地址为深圳市光明区马田街道合水口社区中粮云景广场第1栋1305。如有数据保护相关问题，请致电联系我们。<br/>
（三）账号保护<br/>
在您使用我们的APP服务前，首先需要通过提供手机号或工作单位等信息来注册成为我们的用户，并妥善保管账号信息，并请仔细阅读本协议。<br/>
您在注册和使用本公司服务过程中，本公司将运用各种安全技术手段保护您的账号安全，以免遭受未经授权的访问、使用或披露。<br/>
除法律规定或司法裁定，并征得本公司的同意外，您的服务账号不得以任何方式转让。本公司对此不承担任何责任，您将承担因此产生的任何直接或间接损失及不利后果。<br/>
若您违反国家法律法规或违反本条款，本公司有权中止或终止为您提供服务，并删除您所存储的文件及其他所有信息。本公司应采取商业上的合理措施，根据类似服务的行业标准实施在线服务和您个人数据的安全规程。<br/>
（四）在线服务条款<br/>
（1）本公司将根据APP上发布的用户协议为您提供在线服务。<br/>
（2）您成为本公司用户后，使用在线服务期间，提供和维持在线服务所需的合理范围内，本公司有权收集您的使用、拷贝、储存和传输客户数据来完善我们的服务。有时我们可能需要您提供一些信息，例如在线服务、上传模型、协同设计等，我们可能需要这些信息为您提供更好的服务。同时，请您提供准确、完善的个人信息。<br/>
（3）数据保密。在本公司向用户提供的在线服务期限及协议终止一年内，用户应对公司产品涉密信息进行保密，同时本公司也将保护用户上传的相关个人信息。如有泄露，本公司将有权追究法律责任。<br/>
（五）在线服务期限<br/>
产品提供免费的标准版，标准版支持的功能范围详见产品订阅信息页面。收费政策如有变更，必要时，我们会以短信、邮件、官媒公布的方式通知您。在本协议授予的在线访问和使用权限终止时，请您立即销毁任何已下载或打印好的本APP内容。<br/>
（六）知识产权保护<br/>
本公司APP提供的数据、技术、内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权归本公司所有。<br/>
对于用户在使用中或终止使用后，本公司保留以下所有权：本服务的软件著作权、专利权，本公司商标所有权及其他知识产权。对于您使用该APP上的任何内容，不能被解释为授予您任何使用有关商标的权利。<br/>
上述知识产权均受法律保护，未经本公司书面许可，任何人不得以任何形式使用或创造相关衍生作品。<br/>
若有以下情况发生，本公司将没有义务保护您免遭任何如下相关索赔：<br/>
（1）本公司之外的任何人对产品的任何修改；<br/>
（2）将一款或多款产品与本公司未指定的其他硬件、数据或程序结合使用，或者使用非最新版本的修正补丁或版本。<br/>
（七）用户的权利和义务<br/>
用户只能在公司商业范围内使用服务，禁止发送违反法律的信息，禁止发送和储存带有病毒的、蠕虫的、木马的和其他有害的计算机代码、文件、脚本和程序。<br/>
在本协议的约束下，您可使用本公司提供的互联网技术人信息服务，并可通过本公司发布的渠道获得本公司的技术支持。您保证不会使用任何自动化设备、程序、算法或方法对本APP及服务进行访问，复制或监控。<br/>
如果本APP发现您有上述行为，本公司有权终止一切服务，并且保留对您索赔的权利。<br/>
用户不得通过本公司软件进行以下行为：<br/>
（1）反对宪法所确定的基本原则；<br/>
（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br/>
（3）损坏国家荣誉和利益的；<br/>
（4）煽动民族仇恨、民族歧视，破坏民族团结的；<br/>
（5）破坏国家民族宗教政策，宣杨邪教和封建迷信的；<br/>
（6）散布谣言，扰乱社会秩序，破坏社会稳定的；<br/>
（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br/>
（8）侮辱或者诽谤他人，侵害他人合法权益的；<br/>
（9）含有法律、行政法规禁止的其他内容的。<br/>
如果您违反了本条约定，相关国家机关或机构可能会对您提起诉讼、罚款或采取其他制裁措施，对于造成损害的，您应依法予以赔偿，本公司不承担任何责任。<br/>
（八）本公司的权利和义务<br/>
本公司享有随时更新本服务协议的权利，并在作出修改前不需要经过用户的同意。发生以下情形导致用户无法正常使用的情况，本公司将不承担任何责任：<br/>
（1）本公司之外的人员对产品做的任何修改；<br/>
（2）将一款或多款产品与本公司未指定的其他硬件、数据或程序结合使用；<br/>
（3）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；<br/>
（4）使用非最新版本的修订补丁或版本。<br/>
（九）其他使用限制<br/>
本协议是您行使协议中所规定的权利许可证明，但您不得出于与本公司竟争的目的而分析、进行反向工程、反编译、反汇编或其他企图获取该产品源码的尝试（除适用法律所规定外），因为该产品含有本公司的商业机密（诸如软件的结构、组织和编码）。<br/>
除了出于您的正常业务用途，您不得向任何第三方提供该产品的访问或使用权限。<br/>
（十）免责事项<br/>
在使用本服务的过程中，我们可能会遇到不可抗力等风险因素，使本服务发生中断。<br/>
不可抗力包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如基础电信运营商网络出现严重电力或网络故障，战争、动乱、政府行为、国家政策的突然变动和罢工等。<br/>
若出现上述情况，我们会第一时间对网站信息和公司的产品进行保护，包括保护您的个人信息和维护产品信息，并及时通知相关用户，但是如果因此给您造成的损失，本公司将在法律允许的范围内进行免责。<br/>
公司会提前做相应的措施，来应对不可抗力的情况发生。但互联网数据传输、存储、转换均可能存在一定未知且不确定的数据安全风险，该等风险将导致包括便不限于数据丢失、泄露、损坏、无法读取或提取等后果。<br/>
您确认，您已明确知晓并同意接受该因互联网引发的风险和后果，并已采取恰当的措施（例如数据备份等），以便在该等风险发生时将损失降至最低。<br/><br/>

（十一）责任范围<br/>
本协议受中华人民共和国法律管辖，本协议任何一方违约均须依法承担违约责任。
<br/>
在执行本协议过程中如发生纠纷，双方应及时协商解决。协商不成时，任何一方可直接向本公司所在地法院提起诉讼。<br/>
特别，因互联网技术本身等非本公司主观故意或重大过失导致危害您数据安全的，本公司不承担任何赔偿责任。<br/>
对于内容中所含的版权和其他所有权声明，您应予以尊重并合法使用。在本条款内容公告变更后，若您继续使用本服务，表示您已接受修改后的条款内容，若您不同意修改后的条款内容，应立即停止使用本服务。<br/>

</p>
	<div class="cont-text">版权所有：深圳市小秤砣科技有限公司----版本V1.0</div>
    </div>
    <Footer/>
    <Info/>
  </div>
</template>

<script>

import SiteTopbar from '@/components/header/SiteTopbar'
import Footer from '@/components/PageMain/Bottom/Footer'
import Info from '@/components/PageMain/Bottom/Info'

export default {
  name: "recruit",
  components: {
  SiteTopbar,
    Footer,
    Info
  },
   data(){
    return{
      isOverWX: false
    }
  },
  methods: {
   
    brief(){
     
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  },
    created(){
    const html = document.documentElement;
    document.addEventListener('scroll', (e) => {
      let scrolled = html.scrollTop / (html.scrollHeight - html.clientHeight)
      let scrollTop = document.querySelector('.backtop')

      if(scrolled>0.3){
        scrollTop.style.visibility = 'visible'
      }else{
        scrollTop.style.visibility = 'hidden'
      }
    })
  },
  mounted(){
    this.brief()
  }
 
};
</script>

<style>
.home-main {
  /* padding-top: 4px; */
  /* padding-bottom: 12px; */
  background: rgba(235, 236, 237, 1);

}
.page-main {
  background: #f5f5f5;
}
.containers {
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  

  background: #ffffff;
  border-radius: 40px;
  position: relative;
  top: -100px;
  padding: 80px 60px 60px 60px;

}
.containers p{
color: rgba(69, 77, 89, 1);
font-size: 18px;
line-height: 30px;
font-weight: 400;
}
.headers-imgs{
  /* width: 100%;
  background-image: url('../../assets/imgs/TKK/xie.png');
    background-repeat:no-repeat;
  height: 390px;
  background-size: 100%; */
}
.cont-text{
	margin-top: 30px;
	font-size: 20px;
	text-align: center;
	color: #636b79;
}
</style>